import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Applications = React.lazy(() => import("pages/Applications"));
const Billing = React.lazy(() => import("pages/Billing"));
const Login = React.lazy(() => import("pages/Login"));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={null}>
              <Applications />
            </React.Suspense>
          }
        />
        <Route
          path="/billing"
          element={
            <React.Suspense fallback={null}>
              <Billing />
            </React.Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <React.Suspense fallback={null}>
              <Login />
            </React.Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
